.contact-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0c2d49;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.footer-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.social-wrapper {
  display: flex;
  flex-direction: row;
  padding: 0 30px;
  justify-content: center;
  border-right: 1px white solid;
}

.social-contact {
  color: white;
  font-size: 1.25vw;
  margin: 0 10px;
  z-index: 2;
}

.email-contact {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 1vw;
  padding: 0 30px;
}

.footer-lines-1 {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/icons/Footer_Asset_1.png');
  width: 100%;
  height: 12vw;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(-45%);
}

.footer-lines-2 {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/icons/Footer_Asset_2.png');
  width: 100%;
  height: 5vw;
  position: absolute;
  top: 1vw;
  right: 0;
  transform: translateX(-10%);
}

.footer-stars {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/icons/Footer_Asset_3.png');
  width: 100%;
  height: 15vw;
  position: absolute;
  top: calc(150px - 7vw);
  right: 0;
  transform: translateX(40%);
}

@media screen and (max-width: 1600px) {
  .social-contact {
    font-size: 24px;
  }
  .email-contact {
    font-size: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .footer-lines-1 {
    height: 30vh;
  }
  .footer-lines-2 {
    height: 10vh;
  }
  .footer-stars {
    height: 30vh;
  }
}

@media screen and (max-width: 563px) {
  .social-wrapper {
    border: none;
  }
  .footer-lines-1, .footer-lines-2, .footer-stars {
    display: none;
  }
}