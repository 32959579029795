.App {
  background-image: url('./assets/backgrounds/2024_Full_Bg.png');
  background-repeat: repeat;
  background-size: 100vw;
  background-position: top;
}

* {
  overflow-x: hidden;
}

.general-style {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Source Code Pro', monospace;
}

.section-container {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    color: #ffffff;
    text-align: center;
    padding: 0 5%;
}

.section-title {
    font-size: 4vw;
    color: #ffffff;
    padding-top: 40px;
    font-family: 'Goldman', cursive;
    z-index: 3;
}

.general-button {
    border: none;
    padding: 1.25vw 2vw;
    border-radius: 100px;
    background: linear-gradient(90deg, rgba(67,187,170,255) 0%, rgba(64,131,194,255) 50%, rgba(107,82,161,255) 100%);
    color: #ffffff;
    font-size: 1.5vw;
    font-family: 'Goldman', cursive;
    cursor: pointer;
    margin-bottom: 25px;
}

.general-button:hover {
    box-shadow: 0 0 5px 5px;
}

@media screen and (max-width: 1440px) {
    .general-button {
        padding: 20px 25px;
        font-size: 20px;
        border-radius: 40px;
    }
}

@media screen and (max-width: 916px) {
    .section-title {
        font-size: 3rem;
    }
}

@media screen and (max-width: 500px) {
  .section-title {
      font-size: 2rem;
  }
}